import { $, $$ } from '../helpers/query-selector';
import { gsap, ScrollTrigger } from 'gsap/all';

gsap.registerPlugin(ScrollTrigger);

function init() {
  const main = $('.js-main');
  const layouts = $$('.js-layout');

  for (const layout of layouts) {
    ScrollTrigger.create({
      trigger: layout,
      start: 'top center',
      end: 'bottom center',
      invalidateOnRefresh: true,
      toggleClass: {
        targets: main,
        className: 'bg-' + layout.dataset.bg
      }
    });
  }

  const accordions = $$('.js-accordion-button');

  for (const accordion of accordions) {
    accordion.addEventListener('click', () => {
      ScrollTrigger.refresh(true);
    });
  }

  const formConformation = $('.js-form-confirmation input', form);
  const formCancellation = $('.js-form-cancellation', form);

  formConformation.addEventListener('click', () => {
    ScrollTrigger.refresh(true);
  });

  formCancellation.addEventListener('click', () => {
    ScrollTrigger.refresh(true);
  });
}

export default init;
